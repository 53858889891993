@font-face {
  font-family: "BasisGrotesque";
  src: local("BasisGrotesque"),
    url("./assets/fonts/BasisGrotesqueArabic/BasisGrotesqueArabicPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BasisGrotesque-Light";
  src: local("BasisGrotesque-Light"),
    url("./assets/fonts/BasisGrotesqueArabic/BasisGrotesqueArabicPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "BasisGrotesque-Bold";
  src: local("BasisGrotesque-Bold"),
    url("./assets/fonts/BasisGrotesqueArabic/BasisGrotesqueArabicPro-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "BasisGrotesque", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.AppWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0;
  margin: 0;
}

/* Utils */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}